







































import Vue from 'vue';
import {Test, TestGroup, TestInstance} from '~/models';

export default Vue.extend({
  name: 'TestOverviewPage',
  data() {
    return {
      testsWithExtraTry: [],
      panels: [0],
    };
  },
  async created() {
    const result = await Promise.all([
      this.$store.dispatch('test/fetchAll'),
      this.$store.dispatch('test/fetchAllGroups'),
      this.$store.dispatch('test/getExtraTries'),
    ]);
    this.testsWithExtraTry = result[2];
  },
  computed: {
    tests(): any[] {
      const tests = Test.query().withAll().all();
      return tests.filter(test => test.isDefault && !!test.students.find(s => s.id === this.$store.state.auth.user.id));
    },
    testInstances(): TestInstance[] {
      return TestInstance.query().where('studentId', this.$store.state.auth.user.id).withAll().all();
    },
    testGroups(): any[] {
      const testGroups = TestGroup.query().withAll().all();
      testGroups.unshift({title: this.$t('label.directlyAssignedTests'), tests: this.tests} as any);

      return testGroups
          .filter(group => group.tests.length > 0)
          .map(group => {
            group.tests = group.tests
                .filter(test => test.isDefault)
                .map(test => {
                  const passed = !!this.testInstances.find(instance => instance.testId === test.id && instance.passed);
                  const usedTries = this.testInstances.filter(instance => instance.testId === test.id).length;
                  const allowed = !test.dependentOnId || !!this.testInstances.find(instance => instance.testId === test.dependentOnId && instance.passed);
                  let status = 'locked';

                  if (passed) {
                    status = 'passed';
                  } else if (usedTries >= test.tries && !this.testsWithExtraTry.includes(test.id)) {
                    status = 'notPassed';
                  } else if ((usedTries > 0 && usedTries < test.tries) || usedTries >= test.tries && this.testsWithExtraTry.includes(test.id)) {
                    status = 'attempted';
                  } else if (allowed) {
                    status = 'available';
                  }

                  return {...test, passed, usedTries, allowed, status} as any;
                });
            return group;
          })
          .filter(group => group.tests.length > 0);
    },
    headers(): any[] {
      return [
        {text: this.$t('label.name'), value: 'displayName'},
        {text: this.$tc('p.try', 2), value: 'usedTries', align: 'center'},
        {text: this.$t('label.timeLimit'), value: 'timeLimit'},
        {text: this.$t('label.status'), value: 'status'},
      ];
    },
  },
  methods: {
    getTries(testId: number): number {
      return this.testInstances.filter(instance => instance.testId === testId).length;
    },
  },
});
